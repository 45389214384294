/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDatumType = /* GraphQL */ `
  mutation CreateDatumType(
    $input: CreateDatumTypeInput!
    $condition: ModelDatumTypeConditionInput
  ) {
    createDatumType(input: $input, condition: $condition) {
      id
      nodeTypeId
      index
      name
      labels {
        message
        type
      }
      type
      unit
      digits
      valueMax
      valueMin
      limitHigh
      limitLow
      timeoutInSec
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const updateDatumType = /* GraphQL */ `
  mutation UpdateDatumType(
    $input: UpdateDatumTypeInput!
    $condition: ModelDatumTypeConditionInput
  ) {
    updateDatumType(input: $input, condition: $condition) {
      id
      nodeTypeId
      index
      name
      labels {
        message
        type
      }
      type
      unit
      digits
      valueMax
      valueMin
      limitHigh
      limitLow
      timeoutInSec
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const deleteDatumType = /* GraphQL */ `
  mutation DeleteDatumType(
    $input: DeleteDatumTypeInput!
    $condition: ModelDatumTypeConditionInput
  ) {
    deleteDatumType(input: $input, condition: $condition) {
      id
      nodeTypeId
      index
      name
      labels {
        message
        type
      }
      type
      unit
      digits
      valueMax
      valueMin
      limitHigh
      limitLow
      timeoutInSec
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const createEccMenu = /* GraphQL */ `
  mutation CreateEccMenu(
    $input: CreateEccMenuInput!
    $condition: ModelEccMenuConditionInput
  ) {
    createEccMenu(input: $input, condition: $condition) {
      id
      accountId
      path
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateEccMenu = /* GraphQL */ `
  mutation UpdateEccMenu(
    $input: UpdateEccMenuInput!
    $condition: ModelEccMenuConditionInput
  ) {
    updateEccMenu(input: $input, condition: $condition) {
      id
      accountId
      path
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteEccMenu = /* GraphQL */ `
  mutation DeleteEccMenu(
    $input: DeleteEccMenuInput!
    $condition: ModelEccMenuConditionInput
  ) {
    deleteEccMenu(input: $input, condition: $condition) {
      id
      accountId
      path
      name
      createdAt
      updatedAt
    }
  }
`;
export const createNodeType = /* GraphQL */ `
  mutation CreateNodeType(
    $input: CreateNodeTypeInput!
    $condition: ModelNodeTypeConditionInput
  ) {
    createNodeType(input: $input, condition: $condition) {
      id
      name
      typeIcon
      imagePath
      mapImagePath
      mainDatumIndex
      reversedLabelColor
      permissions
      inquiryChart {
        title
        inquiries {
          id
          title
          description
          image {
            ... on InquiryImageHref {
              href
            }
            ... on InquiryImageS3 {
              s3Key
            }
          }
          options {
            title
            next
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNodeType = /* GraphQL */ `
  mutation UpdateNodeType(
    $input: UpdateNodeTypeInput!
    $condition: ModelNodeTypeConditionInput
  ) {
    updateNodeType(input: $input, condition: $condition) {
      id
      name
      typeIcon
      imagePath
      mapImagePath
      mainDatumIndex
      reversedLabelColor
      permissions
      inquiryChart {
        title
        inquiries {
          id
          title
          description
          image {
            ... on InquiryImageHref {
              href
            }
            ... on InquiryImageS3 {
              s3Key
            }
          }
          options {
            title
            next
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNodeType = /* GraphQL */ `
  mutation DeleteNodeType(
    $input: DeleteNodeTypeInput!
    $condition: ModelNodeTypeConditionInput
  ) {
    deleteNodeType(input: $input, condition: $condition) {
      id
      name
      typeIcon
      imagePath
      mapImagePath
      mainDatumIndex
      reversedLabelColor
      permissions
      inquiryChart {
        title
        inquiries {
          id
          title
          description
          image {
            ... on InquiryImageHref {
              href
            }
            ... on InquiryImageS3 {
              s3Key
            }
          }
          options {
            title
            next
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNode = /* GraphQL */ `
  mutation CreateNode(
    $input: CreateNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    createNode(input: $input, condition: $condition) {
      id
      parentId
      typeId
      name
      imagePath
      featureInfos {
        label
        type
      }
      permissions
      treeIndex
      account
      isWarning
      specifications {
        label
        value
      }
      graphic {
        modelPath
        cameraPositionX
        cameraPositionY
        cameraPositionZ
      }
      camera {
        controllable
        statusDatumId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNode = /* GraphQL */ `
  mutation DeleteNode(
    $input: DeleteNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    deleteNode(input: $input, condition: $condition) {
      id
      parentId
      typeId
      name
      imagePath
      featureInfos {
        label
        type
      }
      permissions
      treeIndex
      account
      isWarning
      specifications {
        label
        value
      }
      graphic {
        modelPath
        cameraPositionX
        cameraPositionY
        cameraPositionZ
      }
      camera {
        controllable
        statusDatumId
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      id
      name
      permissions
      iotDevices {
        id
        dataPoints
        sendCycle
        deployedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      name
      permissions
      iotDevices {
        id
        dataPoints
        sendCycle
        deployedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant(
    $input: DeleteTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    deleteTenant(input: $input, condition: $condition) {
      id
      name
      permissions
      iotDevices {
        id
        dataPoints
        sendCycle
        deployedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createWorkHistory = /* GraphQL */ `
  mutation CreateWorkHistory(
    $input: CreateWorkHistoryInput!
    $condition: ModelWorkHistoryConditionInput
  ) {
    createWorkHistory(input: $input, condition: $condition) {
      id
      time
      nodeId
      workType
      workSummary
      worker
      workNumber
      positionX
      positionY
      positionZ
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkHistory = /* GraphQL */ `
  mutation UpdateWorkHistory(
    $input: UpdateWorkHistoryInput!
    $condition: ModelWorkHistoryConditionInput
  ) {
    updateWorkHistory(input: $input, condition: $condition) {
      id
      time
      nodeId
      workType
      workSummary
      worker
      workNumber
      positionX
      positionY
      positionZ
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkHistory = /* GraphQL */ `
  mutation DeleteWorkHistory(
    $input: DeleteWorkHistoryInput!
    $condition: ModelWorkHistoryConditionInput
  ) {
    deleteWorkHistory(input: $input, condition: $condition) {
      id
      time
      nodeId
      workType
      workSummary
      worker
      workNumber
      positionX
      positionY
      positionZ
      createdAt
      updatedAt
    }
  }
`;
export const createAgvPosition = /* GraphQL */ `
  mutation CreateAgvPosition(
    $input: CreateAgvPositionInput!
    $condition: ModelAgvPositionConditionInput
  ) {
    createAgvPosition(input: $input, condition: $condition) {
      id
      nodeId
      imagePath
      displayName
      floor
      layout
      paperSize
      scale
      revisedPriceX
      revisedPriceY
      theta
      originX
      originY
      type
      datumTypeIds {
        nodeId
        xPositionDatumType
        yPositionDatumType
        floorDatumType
        layoutDatumType
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAgvPosition = /* GraphQL */ `
  mutation UpdateAgvPosition(
    $input: UpdateAgvPositionInput!
    $condition: ModelAgvPositionConditionInput
  ) {
    updateAgvPosition(input: $input, condition: $condition) {
      id
      nodeId
      imagePath
      displayName
      floor
      layout
      paperSize
      scale
      revisedPriceX
      revisedPriceY
      theta
      originX
      originY
      type
      datumTypeIds {
        nodeId
        xPositionDatumType
        yPositionDatumType
        floorDatumType
        layoutDatumType
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgvPosition = /* GraphQL */ `
  mutation DeleteAgvPosition(
    $input: DeleteAgvPositionInput!
    $condition: ModelAgvPositionConditionInput
  ) {
    deleteAgvPosition(input: $input, condition: $condition) {
      id
      nodeId
      imagePath
      displayName
      floor
      layout
      paperSize
      scale
      revisedPriceX
      revisedPriceY
      theta
      originX
      originY
      type
      datumTypeIds {
        nodeId
        xPositionDatumType
        yPositionDatumType
        floorDatumType
        layoutDatumType
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDatum = /* GraphQL */ `
  mutation CreateDatum(
    $input: CreateDatumInput!
    $condition: ModelDatumConditionInput
  ) {
    createDatum(input: $input, condition: $condition) {
      id
      nodeId
      typeId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateDatum = /* GraphQL */ `
  mutation UpdateDatum(
    $input: UpdateDatumInput!
    $condition: ModelDatumConditionInput
  ) {
    updateDatum(input: $input, condition: $condition) {
      id
      nodeId
      typeId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteDatum = /* GraphQL */ `
  mutation DeleteDatum(
    $input: DeleteDatumInput!
    $condition: ModelDatumConditionInput
  ) {
    deleteDatum(input: $input, condition: $condition) {
      id
      nodeId
      typeId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const createEventlog = /* GraphQL */ `
  mutation CreateEventlog(
    $input: CreateEventlogInput!
    $condition: ModelEventlogConditionInput
  ) {
    createEventlog(input: $input, condition: $condition) {
      id
      time
      nodeId
      datumId
      datumName
      type
      event
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const updateEventlog = /* GraphQL */ `
  mutation UpdateEventlog(
    $input: UpdateEventlogInput!
    $condition: ModelEventlogConditionInput
  ) {
    updateEventlog(input: $input, condition: $condition) {
      id
      time
      nodeId
      datumId
      datumName
      type
      event
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventlog = /* GraphQL */ `
  mutation DeleteEventlog(
    $input: DeleteEventlogInput!
    $condition: ModelEventlogConditionInput
  ) {
    deleteEventlog(input: $input, condition: $condition) {
      id
      time
      nodeId
      datumId
      datumName
      type
      event
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const createNodeSecret = /* GraphQL */ `
  mutation CreateNodeSecret(
    $input: CreateNodeSecretInput!
    $condition: ModelNodeSecretConditionInput
  ) {
    createNodeSecret(input: $input, condition: $condition) {
      id
      camera {
        origin
        username
        password
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNodeSecret = /* GraphQL */ `
  mutation UpdateNodeSecret(
    $input: UpdateNodeSecretInput!
    $condition: ModelNodeSecretConditionInput
  ) {
    updateNodeSecret(input: $input, condition: $condition) {
      id
      camera {
        origin
        username
        password
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNodeSecret = /* GraphQL */ `
  mutation DeleteNodeSecret(
    $input: DeleteNodeSecretInput!
    $condition: ModelNodeSecretConditionInput
  ) {
    deleteNodeSecret(input: $input, condition: $condition) {
      id
      camera {
        origin
        username
        password
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNode = /* GraphQL */ `
  mutation UpdateNode(
    $input: UpdateNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    updateNode(input: $input, condition: $condition) {
      id
      parentId
      typeId
      name
      imagePath
      featureInfos {
        label
        type
      }
      permissions
      treeIndex
      account
      isWarning
      specifications {
        label
        value
      }
      graphic {
        modelPath
        cameraPositionX
        cameraPositionY
        cameraPositionZ
      }
      camera {
        controllable
        statusDatumId
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOldDatum = /* GraphQL */ `
  mutation CreateOldDatum(
    $input: CreateOldDatumInput!
    $condition: ModelOldDatumConditionInput
  ) {
    createOldDatum(input: $input, condition: $condition) {
      id
      datumId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateOldDatum = /* GraphQL */ `
  mutation UpdateOldDatum(
    $input: UpdateOldDatumInput!
    $condition: ModelOldDatumConditionInput
  ) {
    updateOldDatum(input: $input, condition: $condition) {
      id
      datumId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteOldDatum = /* GraphQL */ `
  mutation DeleteOldDatum(
    $input: DeleteOldDatumInput!
    $condition: ModelOldDatumConditionInput
  ) {
    deleteOldDatum(input: $input, condition: $condition) {
      id
      datumId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const createOldDatumHourly = /* GraphQL */ `
  mutation CreateOldDatumHourly(
    $input: CreateOldDatumHourlyInput!
    $condition: ModelOldDatumHourlyConditionInput
  ) {
    createOldDatumHourly(input: $input, condition: $condition) {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const updateOldDatumHourly = /* GraphQL */ `
  mutation UpdateOldDatumHourly(
    $input: UpdateOldDatumHourlyInput!
    $condition: ModelOldDatumHourlyConditionInput
  ) {
    updateOldDatumHourly(input: $input, condition: $condition) {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const deleteOldDatumHourly = /* GraphQL */ `
  mutation DeleteOldDatumHourly(
    $input: DeleteOldDatumHourlyInput!
    $condition: ModelOldDatumHourlyConditionInput
  ) {
    deleteOldDatumHourly(input: $input, condition: $condition) {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const createOldDatumDaily = /* GraphQL */ `
  mutation CreateOldDatumDaily(
    $input: CreateOldDatumDailyInput!
    $condition: ModelOldDatumDailyConditionInput
  ) {
    createOldDatumDaily(input: $input, condition: $condition) {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const updateOldDatumDaily = /* GraphQL */ `
  mutation UpdateOldDatumDaily(
    $input: UpdateOldDatumDailyInput!
    $condition: ModelOldDatumDailyConditionInput
  ) {
    updateOldDatumDaily(input: $input, condition: $condition) {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const deleteOldDatumDaily = /* GraphQL */ `
  mutation DeleteOldDatumDaily(
    $input: DeleteOldDatumDailyInput!
    $condition: ModelOldDatumDailyConditionInput
  ) {
    deleteOldDatumDaily(input: $input, condition: $condition) {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const createOperatingRatio = /* GraphQL */ `
  mutation CreateOperatingRatio(
    $input: CreateOperatingRatioInput!
    $condition: ModelOperatingRatioConditionInput
  ) {
    createOperatingRatio(input: $input, condition: $condition) {
      id
      nodeId
      operatingDatumId
      failureDatumId
      operatingLabel
      suspensionLabel
      failureLabel
      createdAt
      updatedAt
    }
  }
`;
export const updateOperatingRatio = /* GraphQL */ `
  mutation UpdateOperatingRatio(
    $input: UpdateOperatingRatioInput!
    $condition: ModelOperatingRatioConditionInput
  ) {
    updateOperatingRatio(input: $input, condition: $condition) {
      id
      nodeId
      operatingDatumId
      failureDatumId
      operatingLabel
      suspensionLabel
      failureLabel
      createdAt
      updatedAt
    }
  }
`;
export const deleteOperatingRatio = /* GraphQL */ `
  mutation DeleteOperatingRatio(
    $input: DeleteOperatingRatioInput!
    $condition: ModelOperatingRatioConditionInput
  ) {
    deleteOperatingRatio(input: $input, condition: $condition) {
      id
      nodeId
      operatingDatumId
      failureDatumId
      operatingLabel
      suspensionLabel
      failureLabel
      createdAt
      updatedAt
    }
  }
`;
